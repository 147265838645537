import React from 'react'
import UseNow from '../Vouchers/Account/UseNow'

const VoucherCard = ({
  loading,
  error,
  item,
  isNewVoucherEnable,
  isVoucher,
  dispatch,
  track,
  i,
  imgLoading,
}) => {
  const expiresAt = item.expiresAt
  let description = item.description

  if (item.type === 'voucher') {
    description = `${item.balance} (${item.description})`
  }

  return (
    !loading &&
    !error && (
      <>
        <UseNow
          key={item.id}
          item={item}
          isNewVoucherEnable={isNewVoucherEnable}
          isVoucher={isVoucher}
          dispatch={dispatch}
          track={track}
          i={i}
          isAllfilterValue={isVoucher}
          imgLoading={imgLoading}
          description={description}
          expiresAt={expiresAt}
        />
      </>
    )
  )
}

export default VoucherCard
